<template>
  <div class="approval-page">
    <div class="approval-page__wrapper">
      <div class="contianerx">
        <img class="contianerx__logo" src="@/assets/hyphen-logo.svg" alt="" />

        <template v-if="step == 1">
          <div>{{ optData }}</div>
          <br />
          <br />

          <v-progress-circular
            v-if="optData === null"
            indeterminate
          ></v-progress-circular>

          <template v-else>
            <form @submit="confirm">
              <v-text-field
                name="name"
                label="OTP"
                v-model="otp"
                outlined
                placeholder="OTP"
              ></v-text-field>

              <div class="d-flex mt-3" style="justify-content: end">
                <v-btn
                  :loading="isVeryfying"
                  color="primary"
                  large
                  type="submit"
                  elevation="0"
                >
                  <v-icon>mdi-chevron-right</v-icon> Confirm</v-btn
                >
              </div>
            </form>
          </template>
        </template>

        <template v-if="step == 2">
          <ul class="listx contianerx__datamsg" v-html="approvalMsg"></ul>
          <div class="approval-actions">
            <v-btn
              style="background-color: #f4f5f6; flex: 1"
              large
              elevation="0"
              :loading="denying"
              @click="denyA"
            >
              <v-icon left>mdi-chevron-right</v-icon> Deny</v-btn
            >
            <v-btn
              @click="approveA"
              color="primary"
              style="flex: 1"
              large
              :loading="approving"
              elevation="0"
              left
            >
              <v-icon left>mdi-chevron-right</v-icon> Approve</v-btn
            >
          </div>

          <div class="view-comments">
            <img :src="require('@/assets/approval-comments.svg')" alt="" />
            <button @click="commentsModal = true">View comments</button>
          </div>
        </template>

        <template v-if="step == 3">
          <div v-if="approve">
            <v-btn fab x-large color="success" outlined>
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <span
              style="margin-top: 30px; padding: 0 65px"
              class="contianerx__desc"
              >Thank you, your selected action <b>- APPROVE -</b> has been
              confirmed.</span
            >
          </div>

          <div v-if="deny">
            <v-btn fab x-large color="error" outlined>
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <span
              style="margin-top: 30px; padding: 0 65px"
              class="contianerx__desc"
              >Thank you, your selectd action <b>- DENY -</b> has been
              confirmed.</span
            >
          </div>
        </template>
      </div>

      <div class="contianerx__footer">
        powered by
        <img :src="require('@/assets/icons/newLogo.svg')" alt="logo" />
      </div>
    </div>

    <v-navigation-drawer
      width="400px"
      v-model="commentsModal"
      temporary
      right
      app
    >
      <div class="comments">
        <div class="comments__header">
          <span>Approval Comments</span>
          <v-btn color="#596A73" icon @click="commentsModal = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="comments__desc">
          Below is a timeline of approvals for this transaction
        </div>

        <div class="timeline">
          <span v-for="(comment, index) in comments" :key="index">
            <div class="timeline__data">
              <div class="head">
                <span class="approver">{{ comment.user.username }}</span>
                <span class="status"
                  >{{ comment.is_approved ? "Approved" : "Denied" }}
                  <img
                    :src="
                      require(`@/assets/approval-${
                        comment.is_approved ? 'approved' : 'denied'
                      }.svg`)
                    "
                  />
                </span>
              </div>

              <div class="quote">
                {{ comment.comment }}
              </div>

              <div class="date">{{ comment.date }}</div>
            </div>
            <div
              v-if="index !== comments.length - 1"
              class="timeline__divider"
            ></div>
          </span>
        </div>
      </div>
    </v-navigation-drawer>

    <v-dialog
      v-model="newCommentDialog"
      :persistent="denying || approving"
      max-width="650px"
      width="90%"
      transition="dialog-transition"
    >
      <div class="action">
        <div class="action__header">
          <div class="b">
            <img
              class="i"
              :src="require('@/assets/actions-get-approval.svg')"
            />
            <span class="t">{{ deny ? "Deny" : "Approve" }}</span>
          </div>
          <v-btn @click="close" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="action__content" :style="'background-color:#f8f7f4'">
          <div class="top">
            <span class="action-title">New Comment</span>
          </div>
          <!-- <span class="action-description"
          >Select the co-worker from who approval is required for this
          stage</span
        > -->

          <div class="a-wrapper">
            <v-textarea
              outlined
              textarea
              color="primary"
              v-model="comment"
              hide-details="auto"
              placeholder="No comment"
            ></v-textarea>
          </div>

          <div class="bottom">
            <v-btn @click="close" large color="primary" outlined>
              <v-icon left>mdi-close</v-icon> Cancel
            </v-btn>

            <v-btn
              @click="submit"
              large
              color="primary"
              :dark="isSDK"
              :loading="denying || approving"
              elevation="0"
            >
              <v-icon left>mdi-chevron-right</v-icon> Submit Approval
            </v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      step: 1,
      otp: null,
      deny: false,
      approve: false,
      approving: false,
      denying: false,
      optData: null,
      approvalMsg: ``,
      isVeryfying: false,
      approvalUrl: null,
      commentsModal: false,
      newCommentDialog: false,
      comment: "",
      comments: [],

      commentId: null,
    };
  },
  mounted() {
    this.fetchOtp();
  },

  watch: {
    newCommentDialog(val) {
      if (val && this.approve) {
        this.comment = "Approved";
      } else if (val && this.deny) {
        this.comment = "";
      }
    },
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    close() {
      if (!this.denying && !this.approving) {
        this.newCommentDialog = false;
        this.deny = false;
        this.approve = false;
      }
    },

    async fetchOtp() {
      try {
        const { data } = await this.$store.dispatch(
          "workflow/fetchApprovalOTP",
          this.$route.query.token
        );
        this.optData = data.data.message;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });

        setTimeout(() => {
          this.$router.replace("/workflow");
        }, 2000);
      }
    },

    async fetchComments() {
      try {
        const { data } = await this.$store.dispatch(
          "workflow/fetchApprovalComments",
          this.commentId
        );
        this.comments = data.data;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });
      }
    },

    denyA() {
      this.deny = true;
      this.approve = false;

      this.newCommentDialog = true;
    },

    approveA() {
      this.deny = false;
      this.approve = true;

      this.newCommentDialog = true;
    },

    async confirm(e) {
      e.preventDefault();
      try {
        this.isVeryfying = true;
        const { data } = await this.$store.dispatch(
          "workflow/verifyApprovalOTP",
          {
            token: this.$route.query.token,
            otp: this.otp,
          }
        );
        this.step = 2;
        this.approvalUrl = data.data;
        this.approvalMsg = data.message;

        // fetch param from url string

        const param = this.$route.query.token.split("/");
        this.commentId = param[param.length - 1];

        this.fetchComments();
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "An error occurred",
          timeout: 3000,
        });

        if (
          // error.msg === "OTP failed verification, not valid or already used" ||
          error.msg ===
          "Invalid action: Approval already recorded, no further action required"
        ) {
          this.$router.replace("/workflow");
        }
      } finally {
        this.isVeryfying = false;
      }
    },

    async submit() {
      if (this.approve) {
        try {
          this.approving = true;
          await this.$store.dispatch("workflow/approvalStatus", {
            url: this.approvalUrl,
            action: 1,
            comment: this.comment,
          });
          this.step = 3;
          this.approve = true;
          this.newCommentDialog = false;

          setTimeout(() => {
            this.$router.replace("/");
          }, 1000);
        } catch (error) {
          console.log(JSON.stringify(error, null, 2));
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg || "An error occurred",
            timeout: 3000,
          });
        } finally {
          this.approving = false;
        }
      }

      if (this.deny) {
        try {
          this.denying = true;
          await this.$store.dispatch("workflow/approvalStatus", {
            url: this.approvalUrl,
            action: 0,
            comment: this.comment,
          });
          this.step = 3;
          this.approve = false;
          this.newCommentDialog = false;

          setTimeout(() => {
            this.$router.replace("/");
          }, 1000);
        } catch (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: error.msg || "An error occurred",
            timeout: 3000,
          });
        } finally {
          this.denying = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approval-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .listx {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: auto;
    margin-bottom: 30px;
    text-align: left;
    :nth-child(even) {
      background-color: #f4f5f6;
      padding: 7px 5px;
      strong {
        color: var(--primary-base);
        color: blue;
      }
    }

    :nth-child(odd) {
      padding: 7px 5px;
      strong {
        color: var(--primary-base);
      }
    }
  }

  .contianerx {
    display: inline-block;
    min-width: 350px;
    background: #ffffff;
    box-shadow: 0px 4px 16pfx rgba(204, 188, 252, 0.15);
    border-radius: 4px;
    text-align: center;
    max-width: 550px;
    width: 90%;
    padding: 25px;
    margin: auto;

    &__logo {
      width: 70px;
      height: 70px;
      margin: 40px auto 20px auto;
    }

    &__desc,
    &__datamsg {
      font-size: 14px;
      color: #19283dcc;
      display: block;
      // text-align: center;
      margin: 10px 0px 40px 0px;
      line-height: 24px;
    }

    &__datamsg {
      text-align: left;
    }

    .approval-actions {
      display: flex;
      gap: 10px;
    }

    &__footer {
      margin: 40px 0 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      color: #8f96a1;

      img {
        height: 15px;
      }
    }
  }

  .view-comments {
    text-align: left;
    margin-top: 50px;
    color: #5b67ba;
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
    }
  }
}

.timeline::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}
.timeline::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
.timeline::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.comments {
  padding: 25px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #19283d;
    }
  }

  &__desc {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #525e6e;
  }

  .timeline {
    flex: 1;
    overflow-y: auto;
    padding: 30px 10px;

    &__divider {
      margin: auto;
      height: 35px;
      width: 1px;
      border-right: 2px dashed #8f96a1;
    }

    &__data {
      box-sizing: border-box;
      padding: 15px;

      background: #ffffff;
      /* line */

      border: 1px solid #d9dee1;
      border-radius: 4px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;

        .approver {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 28px;
          color: #19283d;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status {
          font-family: "Inter";
          font-style: italic;
          font-weight: 500;
          font-size: 13px;
          line-height: 28px;
          color: #8f96a1;
          display: inline-flex;
          align-items: center;
          gap: 5px;
        }
      }

      .quote {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15;
        line-height: 24px;
        /* or 200% */

        /* menu txt */

        color: #525e6e;
        margin: 10px 0px;
      }

      .date {
        text-align: right;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(25, 40, 61, 0.8);
      }
    }
  }
}

.action {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }
    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }
    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }
  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }
    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }
      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }
    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }
    .a-wrapper {
      background-color: #fff;
      margin: 50px 20px;
      border-radius: 4px;

      // mobile view
      @media (max-width: 600px) {
        padding: 0px;
      }
    }
    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 20px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
